import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { styleIf, Theme } from '@txt/core.styles';
import classNames from 'classnames';

export const PAGE_CONTENT_CLASSNAME = 'content-wrap';
export const PAGE_CONTENT_WIDTH = 1340;
export const PAGE_CONTENT_WIDTH_WIDE = 1560;

const Wrap: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  return <div className={classNames(PAGE_CONTENT_CLASSNAME, className)} {...props} />;
};

export const ContentWrap = styled(Wrap)`
  max-width: ${PAGE_CONTENT_WIDTH}px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space[3]};
`;

export const taxonomyDrawerBodyStyles = css`
  display: flex;
  flex-direction: column;

  .${PAGE_CONTENT_CLASSNAME} {
    max-width: ${PAGE_CONTENT_WIDTH_WIDE}px;
  }
`;

const fillSpaceStyles = styleIf(
  'fillSpace',
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
);

const paddedStyles = styleIf(
  'padded',
  (theme) => css`
    padding: ${theme.space[3]};
  `,
);

export const LightBgSection = styled.div<{ fillSpace?: boolean; padded?: boolean }>`
  background: ${({ theme }) => theme.palette.grey[50]};
  ${fillSpaceStyles}
  ${paddedStyles}

  @media print {
    background: transparent;
  }
`;

export const ActiveCardStyles = ({ theme }: { theme: Theme }) => css`
  background: ${theme.palette.white};
  padding: ${theme.space[3]};
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1);
`;

const withShadowStyles = styleIf(
  'withShadow',
  () => css`
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1);
  `,
);

export const ContentSection = styled.div<{ withShadow?: boolean; withoutMargin?: boolean; withoutPadding?: boolean }>`
  background: ${({ theme }) => theme.palette.white};
  ${({ theme, withoutPadding }) =>
    !withoutPadding &&
    css`
      padding: ${theme.space[3]};
    `}

  ${({ theme, withoutMargin }) =>
    !withoutMargin &&
    css`
      margin: ${theme.space[3]} 0;
    `}

  ${withShadowStyles}
`;
