import styled from '@emotion/styled';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowsH,
  faBalanceScaleLeft,
  faBars,
  faBullseye,
  faCalculator,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faChartBullet,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faClone,
  faCloud,
  faCloudDownloadAlt,
  faCodeBranch,
  faCog,
  faColumns,
  faCompress,
  faCopy,
  faDatabase,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFastForward,
  faFileAlt,
  faFilePdf,
  faFilter,
  faGlobe,
  faHistory,
  faIcons,
  faInfo,
  faInfoCircle,
  faLink,
  faLock,
  faLongArrowRight,
  faMagic,
  faMapMarkerAlt,
  faMinus,
  faPause,
  faPencil,
  faPhone,
  faPlay,
  faPlus,
  faPlusCircle,
  faPrint,
  faRandom,
  faRedo,
  faRepeat,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faShareNodes,
  faSignOut,
  faSitemap,
  faSort,
  faSpinner,
  faSubscript,
  faSync,
  faTachometerFastest,
  faTags,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTv,
  faUndo,
  faUser,
  faUserTie,
  faUsers,
  faWindowMaximize,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { PropsApi, ThemeColors, styleProps } from '@txt/core.styles/props';
import * as React from 'react';
import { TooltipInlineBlock, TooltipPlacement } from './tooltip';

/*
 * custom library as fontawesome library does not handle aliases (e.g. renamed icons) correctly:
 * the exported IconDefinition contains the same (new) icon name for both the new export and the alias.
 * => built in IconNames type is misleading/useless as not all names can be resolved in the library
 */

// prettier-ignore
const library = {
  'align-left': faAlignLeft,
  'angle-down': faAngleDown,
  'angle-left': faAngleLeft,
  'angle-right': faAngleRight,
  'arrow-down-wide-short': faArrowDownWideShort,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'arrows-h': faArrowsH,
  'balance-scale-left': faBalanceScaleLeft,
  'bars': faBars,
  'bullseye': faBullseye,
  'calculator': faCalculator,
  'calendar': faCalendar,
  'caret-down': faCaretDown,
  'caret-up': faCaretUp,
  'chart-bullet': faChartBullet,
  'check-circle': faCheckCircle,
  'check': faCheck,
  'chevron-down': faChevronDown,
  'chevron-left': faChevronLeft,
  'chevron-right': faChevronRight,
  'chevron-up': faChevronUp,
  'circle-notch': faCircleNotch,
  'clock': faClock,
  'clone': faClone,
  'cloud-download-alt': faCloudDownloadAlt,
  'cloud': faCloud,
  'code-branch': faCodeBranch,
  'cog': faCog,
  'columns': faColumns,
  'compress': faCompress,
  'copy': faCopy,
  'database': faDatabase,
  'download': faDownload,
  'ellipsis-v': faEllipsisV,
  'envelope': faEnvelope,
  'exclamation-circle': faExclamationCircle,
  'exclamation-square': faExclamationSquare,
  'exclamation-triangle': faExclamationTriangle,
  'exclamation': faExclamation,
  'external-link': faExternalLink,
  'eye-slash': faEyeSlash,
  'eye': faEye,
  'fast-forward': faFastForward,
  'file-alt': faFileAlt,
  'file-pdf': faFilePdf,
  'filter': faFilter,
  'globe': faGlobe,
  'history': faHistory,
  'icons': faIcons,
  'info-circle': faInfoCircle,
  'info': faInfo,
  'link': faLink,
  'lock': faLock,
  'long-arrow-right': faLongArrowRight,
  'magic': faMagic,
  'map-marker-alt': faMapMarkerAlt,
  'minus': faMinus,
  'pause': faPause,
  'pencil': faPencil,
  'phone': faPhone,
  'play': faPlay,
  'plus-circle': faPlusCircle,
  'plus': faPlus,
  'print': faPrint,
  'random': faRandom,
  'redo': faRedo,
  'repeat': faRepeat,
  'save': faSave,
  'search-minus': faSearchMinus,
  'search-plus': faSearchPlus,
  'search': faSearch,
  'share': faShare,
  'share-nodes': faShareNodes,
  'sign-out': faSignOut,
  'sitemap': faSitemap,
  'sort': faSort,
  'spinner': faSpinner,
  'subscript': faSubscript,
  'sync': faSync,
  'tachometer-fastest': faTachometerFastest,
  'tags': faTags,
  'th': faTh,
  'times-circle': faTimesCircle,
  'times': faTimes,
  'trash': faTrash,
  'tv': faTv,
  'undo': faUndo,
  'user-tie': faUserTie,
  'user': faUser,
  'users': faUsers,
  'window-maximize': faWindowMaximize,
};

type AllowedIconNames = keyof typeof library;
type AllowedIconProp = AllowedIconNames | IconLookup;

export type IconName = AllowedIconProp;

export type IconProps = Overwrite<
  FontAwesomeIconProps,
  { noSpacing?: boolean; icon: AllowedIconProp } & Overwrite<
    Omit<PropsApi, 'border'>,
    { color?: Autocomplete<ThemeColors> }
  >
>;

/**
 * Icon wrapper for font-awesome icons
 * NOTE: we need to add the .fa class as we use it some places
 */
export const Icon = styled(
  React.forwardRef<SVGSVGElement, IconProps>(({ className, noSpacing, ...props }, ref) => (
    <FontAwesomeIcon
      {...props}
      color={props.color as string}
      ref={ref}
      // resolve global icons in library
      icon={typeof props.icon === 'string' ? library[props.icon] : props.icon}
      className={`fa ${noSpacing ? 'fa-no-spacing' : ''} ${className ?? ''}`}
    />
  )),
)<IconProps>(styleProps);

/**
 * Icons to be used within a svg context (default size of 16x16)
 *
 * size must be set explicitly as the font-awesome icons have an internal size (viewbox) of 512x512
 */
export const IconSvg: React.FC<
  Overwrite<
    IconProps,
    {
      /** defaults to 16 */
      height?: number;
      /** defaults to 16 */
      width?: number;
    }
  >
> = ({ className, width = 16, height = 16, ...props }) => <Icon {...props} width={width} height={height} />;

const iconThemeDefinition = {
  /** opens new view with the assets in question */
  drillDown: 'info' as const,
};

export const iconTheme: { [K in keyof typeof iconThemeDefinition]: IconName } = iconThemeDefinition;

export const SetBoolIcon: React.FC<{ isTrue: boolean | null }> = ({ isTrue }) => {
  return isTrue ? <Icon icon={faCheck} color="green.500" /> : <Icon icon={faTimes} color="red.500" />;
};

// ClickableIcon
//

type ClickableIconProps = IconProps & {
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  tooltipPlace?: TooltipPlacement;
};

/**
 * An Icon with a clickable function/styles.
 */
export const ClickableIcon: React.FC<ClickableIconProps> = ({
  onClick,
  disabled,
  tooltip,
  tooltipPlace,
  ...iconProps
}) =>
  tooltip ? (
    <TooltipInlineBlock content={tooltip} place={tooltipPlace}>
      <StyledIconClickable onClick={disabled ? undefined : onClick} disabled={disabled} {...iconProps} />
    </TooltipInlineBlock>
  ) : (
    <StyledIconClickable {...iconProps} />
  );

const StyledIconClickable = styled(Icon)<{ disabled?: boolean }>`
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    cursor: default;
    opacity: 0.5;
  `}

  &:hover {
    color: ${(props) => props.theme.palette.primary[500]};
  }
`;
