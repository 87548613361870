import { css, Global, useTheme } from '@emotion/react';
import * as React from 'react';

export const GlobalStyles: React.FC = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        // chakra defaults overrides --- begin

        * {
          font-feature-settings: unset;
        }

        *::selection {
          background: #ff69b455;
        }

        // chakra defaults overrides --- end

        html {
          font-family: ${theme.typography.fonts.body};
        }
        html,
        body {
          margin: 0;
          padding: 0;
          color: ${theme.palette.text};
          font-size: ${theme.typography.baseFontSize}px;
          line-height: ${theme.typography.lineHeight.body};
        }

        body.modal--open {
          height: 100%;
          overflow: hidden;
        }

        /* --- font awesome stylings --- */

        .fa + span {
          /* apply left padding on spans that succeed an icon. */
          padding-left: 0.5rem;
        }

        span + .fa {
          /* apply left padding on icons that succeed a span. */
          padding-left: 0.5rem;

          &.fa-pulse {
            // use margin instead of padding for spinning icons to prevent wobble
            padding-left: 0;
            margin-left: 0.5rem;
          }
        }

        .fa.fa-no-spacing + span {
          padding-left: 0;
        }

        span + .fa.fa-no-spacing {
          padding-left: 0;
        }

        .fa + .fa:not(.fa-spin) {
          padding-left: 0.5rem;
        }

        @media print {
          // use class to hide elements in print mode
          .no-print {
            display: none !important;
          }
        }
      `}
    />
  );
};
