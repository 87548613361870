export function roundNumberToNDecimals(
  v: number,
  nDecimals = 2,
  /** custom rounding function; defaults to Math.round */
  roundingFn: (v: number) => number = Math.round,
): number {
  return roundingFn(v * 10 ** nDecimals) / 10 ** nDecimals;
}

/** Clamps number between min and max. */
export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Linearly interpolates weight between start and end
 */
export const lerp = (
  start: number,
  end: number,
  /** should be [0-1] to stay within bounds */
  weight: number,
  /**
   * clamps the output to [0-1]
   * defaults to true
   */
  clampToRange = true,
): number => {
  const value = start * (1 - weight) + end * weight;
  return clampToRange ? clamp(value, start, end) : value;
};

/**
 * Inverse lerp
 * @returns fraction/weight [0-1] for values within the given range (or when clamped)
 * @returns 0 for empty ranges (start === end)
 */
export const invLerp = (
  start: number,
  end: number,
  value: number,
  /**
   * clamps the output to [0-1]
   * defaults to true
   */
  clampToRange = true,
): number => {
  if (start === end) {
    return 0;
  }
  const fraction = (value - start) / (end > start ? end - start : start - end);
  return clampToRange ? clamp(fraction, 0, 1) : fraction;
};

/**
 * Maps the given value from the input range to the output range
 */
export const mapBetweenRanges = (
  inputStart: number,
  inputEnd: number,
  outputStart: number,
  outputEnd: number,
  value: number,
  /**
   * clamps the output to output range
   * defaults to true
   */
  clampToOutputRange = true,
) => lerp(outputStart, outputEnd, invLerp(inputStart, inputEnd, value, false), clampToOutputRange);

/**
 * Checks wether a value is even
 */
export const isEven = (value: number) => value % 2 === 0;

/**
 * Calculates the fraction of two numbers.
 *
 * @param numerator - The numerator of the fraction.
 * @param denominator - The denominator of the fraction. If the denominator is 0, the function returns 0.
 * @returns The result of the division of the numerator by the denominator, or 0 if the denominator is 0.
 */
export function fraction(numerator: number, denominator: number) {
  return denominator === 0 ? 0 : numerator / denominator;
}

/**
 * Calculates the relative change between a from value and a to value.
 *
 * @returns The relative change as a fraction of the from value.
 */
export function relativeChange(from: number, to: number) {
  return fraction(to - from, from);
}

export function minAndMax<A extends number | undefined, B extends number | undefined>(
  arr: number[],
  defaultMin?: A,
  defaultMax?: B,
): [min: A, max: B] {
  if (arr.length === 0) {
    return [defaultMin as A, defaultMax as B];
  }

  let min: number = arr[0];
  let max: number = arr[0];

  for (let i = 1; i < arr.length; i++) {
    const v = arr[i];
    if (v > max) {
      max = v;
    }
    if (v < min) {
      min = v;
    }
  }

  return [(min ?? defaultMin) as A, (max ?? defaultMax) as B];
}

export function median(arr: number[]): number {
  if (arr.length === 0) {
    return NaN;
  }
  const sortedArr = arr.toSorted((a, b) => a - b);
  const mid = Math.floor(sortedArr.length / 2);
  return sortedArr.length % 2 ? sortedArr[mid] : (sortedArr[mid - 1] + sortedArr[mid]) / 2;
}
