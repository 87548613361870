import { trackUserLogin } from '@txt/core.tracking';
import type { Currency } from '@txt/core.utils/conversion';
import { useUserCurrency } from '@txt/core.utils/hooks';
import { AuthContext, AuthState } from '@txt/taxonomy.ui.common/auth/AuthContext';
import { ContentSection } from '@txt/taxonomy.ui.common/styles/elements';
import * as React from 'react';
import { PublicPageLayout } from '../layout/PublicPageLayout';
import { useMeQuery } from './operations';

export const Auth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [, updateCurrency] = useUserCurrency();
  const { data, loading, error } = useMeQuery();

  const authState = React.useMemo((): AuthState => {
    if (loading) {
      return { isLoading: true };
    }

    return {
      isLoading: false,
      user: data
        ? {
            type: 'InsiderUser',
            id: data.me.id,
            email: data.me.email,
            firstname: data.me.firstname,
            lastname: data.me.lastname,
          }
        : undefined,
    };
  }, [data, loading]);

  React.useEffect(() => {
    if (data?.me.settings.currency) {
      updateCurrency(data.me.settings.currency as Currency);
    }
  }, [data]);

  React.useEffect(() => {
    if (authState.user) {
      trackUserLogin({
        userId: authState.user.id,
        email: authState.user.email,
        fields: {
          type: authState.user.type,
          isAdmin: false,
        },
      });
    }
  }, [authState]);

  if (error?.networkError && 'result' in error.networkError) {
    const result = error.networkError.result;
    if (typeof result === 'object' && result.type === 'EmailNotVerified') {
      return (
        <PublicPageLayout>
          <ContentSection>Please verify your email address.</ContentSection>
        </PublicPageLayout>
      );
    }
  }

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};
