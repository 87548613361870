/**
 * Rounds a number to max two digits.
 * numbers are rounded to the first two non zero digits.
 * e.g. 0.00000020666 -> 0.00000021
 */
export function roundToFirstTwoNonZeroDigits(n: number): number {
  if (n === 0) {
    return 0;
  }

  const absN = Math.abs(n);
  const factor = Math.pow(10, 1 - Math.floor(Math.log10(absN)));

  return Math.round(n * factor) / factor;
}
