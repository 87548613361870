import { ChakraProvider as ChakraUIProvider, createSystem, defaultConfig, SystemContext } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider, Global, ThemeProvider } from '@emotion/react';
import * as React from 'react';
import { GlobalStyles } from './GlobalStyles';
import { GlobalFontStyles } from './fonts/GlobalFontStyles';

const colors = {
  white: '#ffffff',
  black: '#000000',
  green: {
    '50': '#e6fff5',
    '100': '#c9f7e6',
    '200': '#abefd8',
    '300': '#8ae7cb',
    '400': '#62dfbe',
    '500': '#22d6b2',
    '600': '#21a788',
    '700': '#1b7b62',
    '800': '#12513e',
    '900': '#072b1f',
  },
  grey: {
    '50': '#f3f3f4',
    '100': '#d7d7d9',
    '200': '#bdbdbf',
    '300': '#a2a2a5',
    '400': '#89898c',
    '500': '#707074',
    '600': '#59595c',
    '700': '#424246',
    '800': '#2d2d31',
    '900': '#1a1a1d',
  },
  blue: {
    '50': '#e8f3f9',
    '100': '#c1ddef',
    '500': '#0084ce',
    '600': '#086ba7',
    '800': '#0e3d5d',
  },
  red: {
    '50': '#fef2f2',
    '100': '#ffd3cf',
    '200': '#ffb4a7',
    '300': '#ff977b',
    '400': '#ff7b4a',
    '500': '#f56200',
    '600': '#cb4612',
    '700': '#9f2e15',
    '800': '#741912',
    '900': '#4a0807',
  },
  orange: {
    '50': '#fff3e0',
    '100': '#ffe2b6',
    '500': '#ff9102',
    '600': '#d06e10',
    '800': '#72330e',
  },
  yellow: {
    '50': '#fefce8',
    '100': '#fffbc7',
    '400': '#fff659',
    '500': '#e1c343',
    '700': '#976924',
    '800': '#6d4217',
  },
};

const lineHeight = {
  none: 1,
  dense: 1.125,
  tight: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2,
};

const boxShadowLayer1 = '0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12)';
const boxShadowFloating = 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px';
const cardShadow = `inset 1px -1px 0 0 ${colors.grey[100]}`;

const baseFontSize = 13;
const px2rem = (px: number): string => `${Number(px / baseFontSize).toFixed(3)}rem`;

// try to go with material-ui structure https://material-ui.com/customization/default-theme/
export const theme = {
  palette: {
    ...colors,
    /** Global text color. */
    text: colors.grey[700],
    primary: colors.green,
    eol: {
      outdated: {
        background: colors.red[50],
        border: colors.red[500],
      },
      soonOutdated: {
        background: colors.orange[50],
        border: colors.orange[500],
      },
    },
    status: {
      info: {
        main: colors.blue[500],
        background: colors.blue[50],
        hd: colors.blue[600],
        text: colors.blue[800],
      },
      success: {
        main: colors.green[500],
        background: colors.green[50],
        hd: colors.green[600],
        text: colors.green[800],
      },
      warning: {
        main: colors.yellow[500],
        background: colors.yellow[50],
        hd: colors.yellow[700],
        text: colors.yellow[800],
      },
      error: {
        main: colors.red[500],
        background: colors.red[50],
        hd: colors.red[600],
        text: colors.red[800],
      },
    },
    coloredBg: 'linear-gradient(17.27deg, #fbf7e4 0%, #1dbe9f 58.55%, #00a186 100%)',
  },
  mixins: {
    card: {
      bg: colors.white,
      shadow: cardShadow,
    },
  },
  shadows: {
    1: boxShadowLayer1,
    card: cardShadow,
    floating: boxShadowFloating,
  },
  typography: {
    fonts: {
      body: 'Inter, Arial, Helvetica, sans-serif',
      heading: 'Inter, Arial, Helvetica, sans-serif',
      monospace: 'Consolas, Courier, monospace',
    },
    baseFontSize,
    fontSizes: {
      xl9: px2rem(54),
      xl8: px2rem(40),
      xl7: px2rem(30),
      xl6: px2rem(24),
      xl5: px2rem(20),
      xl4: px2rem(18),
      xl3: px2rem(16),
      xl2: px2rem(15),
      xl: px2rem(14.5),
      lg: px2rem(14),
      md: px2rem(baseFontSize),
      sm: px2rem(12),
      xs: px2rem(11),
      xs2: px2rem(10),
    },
    fontWeights: {
      normal: 400,
      semiBold: 600,
    },
    lineHeight: {
      ...lineHeight,
      body: lineHeight.dense,
      heading: lineHeight.dense,
      longText: lineHeight.snug,
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0em',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
  },
  dimensions: {
    headerHeight: 50,
    navBreakWidthSmall: 1519,
    navBreakWidthMobile: 1349,
    maxSmallContainerWidth: 1536,
  },
  space: {
    '0': '0',
    '1': '4px',
    '2': '8px',
    '3': '12px',
    '4': '16px',
    '5': '20px',
    '6': '24px',
    '8': '32px',
    '12': '48px',
    '14': '56px',
    '16': '64px',
    '18': '72px',
    '24': '96px',
    '32': '128px',
  },
  isAccMyNavStyle: false,
  portalZIndex: 1001,
};

export type Theme = typeof theme;

export type ThemeColors = LeavePath<Theme['palette']> | 'transparent';

/**
 * Special theme for our ACN MyNav instances.
 * The theme is activated via a specific txture.properties setting.
 */
const MyNavTheme: Theme = {
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      '50': '#e6edfd',
      '100': '#d3deff',
      '200': '#aec2ff',
      '300': '#819fff',
      '400': '#4b7aff',
      '500': '#004dff',
      '600': '#0a3bab',
      '700': '#0b257b',
      '800': '#05164a',
      '900': '#06102f',
    },
    coloredBg: 'linear-gradient(17.27deg, rgb(140, 175, 255) 0%, rgb(0, 77, 255) 75%)',
  },
  dimensions: {
    ...theme.dimensions,
    navBreakWidthSmall: 1819,
    navBreakWidthMobile: 1649,
  },

  isAccMyNavStyle: true,
};

const cache = createCache({
  key: 'txt',
  // disable the vendor prefixes in development for better readability
  ...(process.env.NODE_ENV === 'development' && { stylisPlugins: [] }),
});

const EmotionProvider: React.FC<{ theme: Theme; children: React.ReactNode }> = ({ theme, children }) => (
  <CacheProvider value={cache}>
    <Global
      styles={`
        body {
          --colors-primary-50: ${theme.palette.primary[50]};
          --colors-primary-200: ${theme.palette.primary[200]};
          --colors-primary-300: ${theme.palette.primary[300]};
          --colors-primary-500: ${theme.palette.primary[500]};
          --colors-primary-600: ${theme.palette.primary[600]};

          --colors-text: ${theme.palette.text};
          --colors-blue: ${theme.palette.blue[500]};

          --colors-grey-50: ${theme.palette.grey[50]};
          --colors-grey-100: ${theme.palette.grey[100]};
          --colors-grey-200: ${theme.palette.grey[200]};
          --colors-grey-300: ${theme.palette.grey[300]};
          --colors-grey-500: ${theme.palette.grey[500]};

          --colors-error: ${theme.palette.status.error.main};
          --colors-warning: ${theme.palette.status.warning.main};
          --colors-action-focus: ${theme.palette.primary[50]};
          --colors-action-disabled: ${theme.palette.grey[200]};

          --card-bg: ${theme.mixins.card.bg};

          --shadows-card: ${theme.shadows.card};
          --shadows-floating: ${theme.shadows.floating};

          --portal-zIndex: ${theme.portalZIndex};
        }
      `}
    />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </CacheProvider>
);

// Special Chakrea css class, see description below.
export const chakraResetClassPrefix = 'chakra-reset';

// Our css prop requires our theme, but chakra expects theirs.
// As an easy solution, lets not allow the css prop in Chakra components.
export type BannedChakraProps = 'css';

function createChakraSystem(txtureTheme: Theme): SystemContext {
  return createSystem(defaultConfig, {
    preflight: {
      // This class gets added to all selectors in the Chakra reset layer.
      // Adding this prevents Chakra's reset styles from leaking into txture.
      // For Chakra components to work, all root Chakra ui elements need this class.
      // FIXME: Come up with a better solution to blend these two theming methods.
      scope: `.${chakraResetClassPrefix}`,
      level: 'parent',
    },

    // TODO: Fix in: https://txture.atlassian.net/browse/TXT-14454
    // This is an example how we can apply our current theme values to the Chakra theme.
    // But of course it would be better to just have a single theme...
    theme: {
      tokens: {
        spacing: Object.fromEntries(Object.entries(txtureTheme.space).map(([key, value]) => [key, { value }])),

        // https://www.chakra-ui.com/docs/theming/z-index
        // making sure chakra uses our z-index values for important stuffs.
        zIndex: {
          dropdown: { value: theme.portalZIndex },
          sticky: { value: theme.portalZIndex },
          banner: { value: theme.portalZIndex },
          overlay: { value: theme.portalZIndex },
          modal: { value: theme.portalZIndex },
        },
      },
    },
  });
}

const ChakraProvider: React.FC<{ theme: Theme; children: React.ReactNode }> = ({ theme, children }) => {
  const chakraSystem = React.useMemo(() => createChakraSystem(theme), [theme]);
  return <ChakraUIProvider value={chakraSystem}>{children}</ChakraUIProvider>;
};

export const TxtureThemeProvider: React.FC<{ useMyNavStyling?: boolean; children: React.ReactNode }> = ({
  useMyNavStyling = false,
  children,
}) => {
  const txtureTheme = useMyNavStyling ? MyNavTheme : theme;

  // TODO: Fix in: https://txture.atlassian.net/browse/TXT-14454
  // It would be nice to have just a single theme and provider.
  return (
    <EmotionProvider theme={txtureTheme}>
      <ChakraProvider theme={txtureTheme}>
        <GlobalFontStyles />
        <GlobalStyles />

        {children}
      </ChakraProvider>
    </EmotionProvider>
  );
};
