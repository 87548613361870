import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from './Box';
import { Flex } from './Flex';
import { styleIf } from './styleIf';

const gapStyles = styleIf(
  'padded',
  (theme) => css`
    gap: ${theme.space[3]};
  `,
);

const paddingStyles = styleIf(
  'padded',
  (theme) => css`
    padding: 0 ${theme.space[6]};
  `,
);

export const Grid = {
  Row: styled(Flex)<{ padded?: boolean }>`
    ${gapStyles}

    @media (max-width: 900px) {
      display: block;
    }
  `,
  Col: styled(Box)<{ padded?: boolean }>`
    @media (min-width: 901px) {
      width: 50%;
      ${paddingStyles}
    }
  `,
};
