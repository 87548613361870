import { logDebug } from '@txt/core.tracking';
import { IntlFormatters, useIntl } from 'react-intl';
import { getStaticIntl, registerI18Messages } from './IntlProvider';
import { FormattedMessage, Props as FormattedMessageProps } from './components/FormattedMessage';

type Messages<LanguageKeys extends string> = {
  en: Record<LanguageKeys, string>;
  de?: Partial<Record<LanguageKeys, string>>;
};

const DEBUG_LOG = false;

type MessageDescriptor<LanguageKeys extends string = string> = {
  id: LanguageKeys;
  description?: string;
  /** @deprecated */
  defaultMessage?: string;
};

type ScopedFormatMessage<LanguageKeys extends string> = (
  messageDescriptor: MessageDescriptor<LanguageKeys> | LanguageKeys,
  values?: { [key: string]: string | number },
) => string;

function callFormatMessage(
  formatMessage: IntlFormatters['formatMessage'],
  messageDescriptor: MessageDescriptor | string,
  values?: { [key: string]: string | number },
): string {
  if (typeof messageDescriptor === 'string') {
    return formatMessage({ id: messageDescriptor }, values);
  } else {
    return formatMessage(messageDescriptor, values);
  }
}

/**
 * Registers new language messages and returns typed intl components.
 * This ensures that only registered translations can be used.
 *
 * @param messages additional messages
 */
export function createIntlScope<LanguageKeys extends string>(messages: Messages<LanguageKeys>, scopeName?: string) {
  if (DEBUG_LOG && scopeName) {
    logDebug('Register intl scope', { scopeName });
  }
  registerI18Messages(
    messages as {
      en: Record<LanguageKeys, string>;
      de?: Record<LanguageKeys, string>; // partial typing does not work...
    },
  );

  return {
    FormattedMessage: FormattedMessage as React.FC<FormattedMessageProps<LanguageKeys>>,
    formatMessage: ((messageDescriptor, values) =>
      callFormatMessage(getStaticIntl().formatMessage, messageDescriptor, values)) as ScopedFormatMessage<LanguageKeys>,
    useFormatMessage: (): ScopedFormatMessage<LanguageKeys> => {
      const intl = useIntl();
      return (messageDescriptor, values) => callFormatMessage(intl.formatMessage, messageDescriptor, values);
    },
  };
}

/**
 * Creates aliases ids for existing messages ids. This is especially useful if yo want to reuse
 * messages in different contexts.
 *
 * const patchTypes = ['MAJOR', 'MINOR', 'PATCH'] as const;
 *
 * type PatchType = typeof patchTypes[number];
 *
 * const patchTypeAliases = {
 *   'patchType.MAJOR': 'major',
 *   'patchType.MINOR': 'minor',
 *   'patchType.PATCH': 'patch',
 * } as const satisfies Record<`patchType.${PatchType}`, string>; // make sure that every PatchType is aliased
 *
 * const messages = withAliases(
 *   {
 *     en: {
 *       major: 'Major',
 *       minor: 'Minor',
 *       patch: 'Patch',
 *     },
 *   },
 *   patchTypeAliases // type safe, as the keys are checked against the messages
 * );
 *
 * messages will be aliased for en and de if available
 *
 * patchTypes.map((t) => <FormattedMessage id={`patchType.${t}`} />); // works
 */
export function withAliases<LanguageKeys extends string, A extends Record<string, LanguageKeys>>(
  messages: Messages<LanguageKeys>,
  aliases: A,
): Messages<LanguageKeys | (keyof A & string)> {
  return {
    en: {
      ...messages.en,
      ...(Object.fromEntries(Object.entries(aliases).map(([alias, key]) => [alias, messages.en[key]])) as Record<
        keyof A,
        string
      >),
    },
    de: {
      ...messages.de,
      ...Object.fromEntries(Object.entries(aliases).map(([alias, key]) => [alias, messages.de?.[key]])),
    },
  };
}
