import { roundNumberToNDecimals } from '../math';

export type UnitConverter<T extends string> = {
  (value: number, from: T, to: T, round?: boolean): number;
};

export type UnitConversion<T extends string> = {
  unit: T;
  value: number;
};

export type ReadableUnitConversionResult<T extends string> = {
  conversions: UnitConversion<T>[];
  bestFit: UnitConversion<T>;
  bestFitStr: string;
  readableConversions: UnitConversion<T>[];
};

/**
 * finds the "best fitting" unit for a given value
 * where best fitting means smallest conversion greater or equal to 1
 *
 * @param convertUnit function which converts a value from unit A to unit B
 * @param units sorted (smallest to biggest unit) subset of available units
 * @param baseUnit the unit of the initial value
 **/
export function findReadableUnitConversion<T extends readonly string[]>(
  convertUnit: UnitConverter<T[number]>,
  units: T,
  baseUnit: T[number],
  maxDecimals?: number,
) {
  return (initialValue: number): ReadableUnitConversionResult<T[number]> => {
    const conversions = units.map((unit) => {
      const convertedUnit = convertUnit(initialValue, baseUnit, unit);
      return {
        unit,
        value: maxDecimals ? roundNumberToNDecimals(convertedUnit, maxDecimals) : convertedUnit,
      };
    });

    if (initialValue === 0) {
      const bestFit = conversions.find((c) => c.unit === baseUnit)!;
      return {
        conversions,
        bestFit,
        bestFitStr: bestFitToString(bestFit),
        readableConversions: [bestFit],
      };
    }

    const firstValueSmallerOne = conversions.findIndex(({ value }) => Math.abs(value) < 1);
    const bestFit = Math.max(
      0,
      firstValueSmallerOne === -1
        ? conversions.length - 1 // biggest conversion available
        : firstValueSmallerOne - 1, // bestFit
    );
    return {
      conversions,
      bestFit: conversions[bestFit],
      bestFitStr: bestFitToString(conversions[bestFit]),
      readableConversions: conversions.slice(Math.max(bestFit - 1, 0), bestFit + 2),
    };
  };
}

function bestFitToString<T extends string>(bestFit: UnitConversion<T>) {
  return `${bestFit.value} ${bestFit.unit}`;
}
