import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColors } from '@txt/core.styles';
import * as React from 'react';
import { variant as styledSystemVariant, system } from 'styled-system';
import { Icon, IconName } from './Icon';
import { Status, isStatus } from './StatusDot';
import { StatusIcon } from './StatusIcon';
import { FormattedMessage } from './i18n';

type Props = {
  variant?: 'default' | Status;
  className?: string;
  noMargin?: boolean;
  heading?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  bg?: ThemeColors;
  icon?: IconName;
};

export const Notice: React.FC<Props> = ({
  variant = 'default',
  className,
  noMargin,
  heading,
  actions,
  children,
  icon,
  bg,
}) => {
  const status = isStatus(variant) ? variant : undefined;

  return (
    <Styles.Root className={className} variant={variant} noMargin={noMargin} backgroundColor={bg}>
      {icon ? <Icon fixedWidth icon={icon} /> : status ? <StatusIcon status={status} /> : null}

      <Styles.ContentWrap>
        {heading ? (
          <Styles.Heading variant={variant}>{heading}</Styles.Heading>
        ) : status && heading !== null ? (
          <Styles.Heading variant={variant}>
            <FormattedMessage id={`notice.header.${status}`} />
          </Styles.Heading>
        ) : null}

        {children}
      </Styles.ContentWrap>

      <Styles.ActionWrap>{actions}</Styles.ActionWrap>
    </Styles.Root>
  );
};

const background = system({
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'palette',
  },
});

const Styles = {
  Root: styled.div<{ variant: Props['variant']; backgroundColor?: ThemeColors; noMargin?: boolean }>`
    display: flex;
    align-items: center;
    margin: ${({ noMargin }) => (noMargin ? 0 : '1em 0')};
    gap: ${({ theme }) => theme.space[3]};
    padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
    border-left: 4px solid;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-wrap: break-word;

    ${({ theme }) =>
      styledSystemVariant({
        prop: 'variant',
        variants: {
          default: css`
            background: ${theme.palette.white};
            border-color: ${theme.palette.primary[500]};
            color: ${theme.palette.text};

            > svg {
              color: ${theme.palette.primary[500]};
            }
          `,
          info: css`
            background: ${theme.palette.status.info.background};
            border-color: ${theme.palette.status.info.main};
            color: ${theme.palette.status.info.text};

            > svg {
              color: ${theme.palette.status.info.main};
            }
          `,
          success: css`
            background: ${theme.palette.status.success.background};
            border-color: ${theme.palette.status.success.main};
            color: ${theme.palette.status.success.text};

            > svg {
              color: ${theme.palette.status.success.main};
            }
          `,
          warning: css`
            background: ${theme.palette.status.warning.background};
            border-color: ${theme.palette.status.warning.main};
            color: ${theme.palette.status.warning.text};

            > svg {
              color: ${theme.palette.status.warning.main};
            }
          `,
          error: css`
            background: ${theme.palette.status.error.background};
            border-color: ${theme.palette.status.error.main};
            color: ${theme.palette.status.error.text};

            > svg {
              color: ${theme.palette.status.error.main};
            }
          `,
        },
      })}

    ${background}

    > svg {
      font-size: ${(p) => p.theme.typography.fontSizes.xl3};
    }
  `,

  ContentWrap: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;
    gap: ${({ theme }) => theme.space[2]};
  `,

  Heading: styled.h1<{ variant: Props['variant'] }>`
    font-size: ${({ theme }) => theme.typography.fontSizes.xl2};
    font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
    line-height: ${({ theme }) => theme.typography.lineHeight.normal};

    margin: 0;
    ${({ theme: { palette } }) =>
      styledSystemVariant({
        prop: 'variant',
        variants: {
          default: css`
            color: inherit;
          `,
          info: css`
            color: ${palette.status.info.hd};
          `,
          success: css`
            color: ${palette.status.success.hd};
          `,
          warning: css`
            color: ${palette.status.warning.hd};
          `,
          error: css`
            color: ${palette.status.error.hd};
          `,
        },
      })}
  `,

  ActionWrap: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.space[2]};

    // NOTE: unfortunately we need this to disable the '& + button' css rule from ButtonStyles.tsx which adds margin-left: 1em
    & > button {
      margin-left: 0 !important;
    }
  `,
};
